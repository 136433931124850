import React from 'react';

const Eraser: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.00146 21.0415C1.00146 20.4892 1.44918 20.0415 2.00146 20.0415L22.0035 20.0415C22.5558 20.0415 23.0035 20.4892 23.0035 21.0415C23.0035 21.5938 22.5558 22.0415 22.0035 22.0415L2.00146 22.0415C1.44918 22.0415 1.00146 21.5938 1.00146 21.0415Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.8116 1.55512C15.3848 0.965292 16.326 0.946978 16.9217 1.51406L22.5585 6.88044C23.1587 7.45181 23.1819 8.40159 22.6103 9.00154L14.6474 17.3593L14.597 17.3927C14.5937 17.3958 14.59 17.3992 14.5858 17.403C14.5499 17.4364 14.498 17.4864 14.4295 17.5538C14.2934 17.6878 14.1096 17.8734 13.892 18.0958C13.4577 18.5397 12.902 19.1167 12.3525 19.6905C11.8036 20.2638 11.2629 20.8316 10.8593 21.2563C10.6575 21.4686 10.4901 21.645 10.3732 21.7684L10.19 21.9617L8.73757 20.5868L8.92189 20.3923C9.03925 20.2685 9.20718 20.0915 9.40952 19.8786C9.81413 19.4528 10.3567 18.883 10.908 18.3072C11.4589 17.732 12.0205 17.1488 12.4624 16.6971C12.5472 16.6104 12.6281 16.5281 12.704 16.4512C11.9866 15.4033 11.3767 14.6188 10.6445 13.9308C9.88139 13.2138 8.95297 12.5723 7.57498 11.8722L3.22936 16.3443L7.48354 20.023L6.17536 21.5358L1.52034 17.5105C0.867812 16.9463 0.824532 15.9492 1.42572 15.3306L14.8116 1.55512ZM9.02861 10.3763C10.253 11.0396 11.1955 11.7042 12.014 12.4733C12.8078 13.2191 13.4614 14.042 14.1324 15.0004L20.8172 7.98409L15.9011 3.30385L9.02861 10.3763Z" fill="currentColor" />
  </svg>
);

export default Eraser;
